/** When your routing table is too long, you can split it into small modules**/

const business = {
  path: '/business',
  component: () => import('@/layouts/MainLayout'),
  children: [
    {
      path: 'connection',
      name: 'businessConnection',
      component: () => import('@/views/business/Connection')
    },
    {
      path: 'web',
      name: 'businessWeb',
      component: () => import('@/views/business/Web')
    },
    {
      path: 'graphic',
      name: 'businessGraphic',
      component: () => import('@/views/business/Graphic')
    },
    {
      path: 'sale',
      name: 'businessSale',
      component: () => import('@/views/business/Sale')
    }
  ]
}

export default business
