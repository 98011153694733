import Vue from 'vue'
import VueRouter from 'vue-router'

import company from './modules/company.module'
import business from './modules/business.module'
import product from './modules/product.module'
import customer from './modules/customer.module'
import info from './modules/info.module'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/layouts/HomeLayout')
  },
  company,
  business,
  product,
  customer,
  info,
  {
    path: '/agency',
    name: 'Agency',
    component: () => import('@/views/Agency')
  },
  {
    path: '/agency/*',
    name: 'Agency',
    component: () => import('@/views/Agency')
  }
]

// Always leave this as last one
if (process.env.MODE !== 'ssr') {
  routes.push({
    path: '*',
    component: () => import('@/views/Error404.vue')
  })
}

export default routes
