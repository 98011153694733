/** When your routing table is too long, you can split it into small modules**/

const company = {
  path: '/company',
  component: () => import('@/layouts/MainLayout'),
  children: [
    {
      path: 'about',
      name: 'companyAbout',
      component: () => import('@/views/company/About')
    },
    {
      path: 'ceo',
      name: 'companyCEO',
      component: () => import('@/views/company/CEO')
    },
    {
      path: 'history',
      name: 'companyHistory',
      component: () => import('@/views/company/History')
    },
    {
      path: 'vision',
      name: 'companyVision',
      component: () => import('@/views/company/Vision')
    },
    {
      path: 'orga',
      name: 'companyOrganization',
      component: () => import('@/views/company/Organization')
    },
    {
      path: 'ci',
      name: 'companyCI',
      component: () => import('@/views/company/CI')
    },
    {
      path: 'map',
      name: 'companyMap',
      component: () => import('@/views/company/Map')
    }
  ]
}

export default company
