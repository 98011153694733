<template>
  <div>
    <header class="main-header">
      <top-menu></top-menu>
    </header>
    <router-view></router-view>
    <footer>
      <div class="footer-top-border"></div>
      <foot></foot>
    </footer>
  </div>
</template>

<script>
import TopMenu from '@/components/TopMenu'
import Foot from '@/components/Foot'
export default {
  name: 'App',
  components: {
    'top-menu': TopMenu,
    foot: Foot
  }
}
</script>

<style scoped>
header.main-header {
  width: 100%;
  height: 76px;
  background-image: url('/images/top-background.png');
  background-repeat: repeat-x;
}
footer div.footer-top-border {
  width: 100%;
  min-width: 1000px;
  height: 52px;
  background-image: url('/images/footer-top-border.png');
  background-repeat: repeat-x;
  margin-bottom: 14px;
}
</style>
