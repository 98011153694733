/** When your routing table is too long, you can split it into small modules**/

const info = {
  path: '/info',
  component: () => import('@/layouts/MainLayout'),
  children: [
    {
      path: 'sitemap',
      name: 'infoSitemap',
      component: () => import('@/views/info/Sitemap')
    },
    {
      path: 'policy',
      name: 'infoPolicy',
      component: () => import('@/views/info/Policy')
    }
  ]
}

export default info
