<template>
  <div id="top-menu">
    <div class="menu-wrap">
      <div id="main-logo">
        <router-link to="/">
          <img src="/images/logo.png" alt="" />
        </router-link>
      </div>
      <div id="main-menu">
        <div class="sub-menu-wrap">
          <div class="sub-menu">
            <ul>
              <li>
                <router-link to="/">HOME</router-link>
              </li>
              <li>
                <router-link to="/info/sitemap">SITEMAP</router-link>
              </li>
              <li>
                <a href="mailto:allmaster@mocons.com">CONTACT US</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="main-menu-wrap">
          <div class="main-menu">
            <ul>
              <li @mouseover="changeMenu(0)" @mouseleave="resetMenu(0)">
                <router-link to="/company/about">{{ menu1 }}</router-link>
              </li>
              <li @mouseover="changeMenu(1)" @mouseleave="resetMenu(1)">
                <router-link to="/business/connection">{{ menu2 }}</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'menu-component',
  data() {
    return {
      menu1: 'COMPANY',
      menu2: 'BUSINESS',
      menu3: 'PRODUCT',
      menu4: 'CUSTOMER',
      mainMenu: [
        {
          menuEngName: 'COMPANY',
          menuKorName: '회사소개',
          url: '/company'
        },
        {
          menuEngName: 'BUSINESS',
          menuKorName: '사업소개',
          url: '/business'
        },
        {
          menuEngName: 'PRODUCT',
          menuKorName: '상품소개',
          url: '/product'
        },
        {
          menuEngName: 'CUSTOMER',
          menuKorName: '고객센터',
          url: '/customer'
        }
      ]
    }
  },
  methods: {
    changeMenu(menuNum) {
      const self = this
      switch (menuNum) {
        case 0:
          self.menu1 = this.mainMenu[menuNum].menuKorName
          break
        case 1:
          self.menu2 = this.mainMenu[menuNum].menuKorName
          break
        case 2:
          self.menu3 = this.mainMenu[menuNum].menuKorName
          break
        default:
          self.menu4 = this.mainMenu[menuNum].menuKorName
          break
      }
    },
    resetMenu(menuNum) {
      const self = this
      switch (menuNum) {
        case 0:
          self.menu1 = this.mainMenu[menuNum].menuEngName
          break
        case 1:
          self.menu2 = this.mainMenu[menuNum].menuEngName
          break
        case 2:
          self.menu3 = this.mainMenu[menuNum].menuEngName
          break
        default:
          self.menu4 = this.mainMenu[menuNum].menuEngName
          break
      }
    }
  }
}
</script>

<style scoped>
#top-menu {
  width: 1000px;
  height: 100%;
  margin: 0 auto;
  display: flex;
}
#top-menu .menu-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
#main-logo {
  height: 100%;
  padding-left: 20px;
  display: flex;
  align-items: center;
}
#main-logo img {
  display: block;
  width: 119px;
  height: 48px;
}
#main-menu {
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;
}
#main-menu .sub-menu-wrap {
  width: 100%;
  height: 18px;
  display: flex;
  flex-direction: row-reverse;
}
#main-menu .main-menu-wrap {
  height: 36px;
}
#main-menu .sub-menu {
  width: 220px;
  border-radius: 15px;
  padding: 4px 0;
  background-color: #b2b2b2;
}
#main-menu .sub-menu-wrap ul {
  width: 100%;
  padding: 0 6px;
  font-size: 10px;
  color: #fff;
  list-style: none;
  display: flex;
  justify-content: space-around;
  box-sizing: border-box;
}
#main-menu .sub-menu-wrap ul li {
  display: block;
}
#main-menu .main-menu {
  width: 100%;
}
#main-menu .main-menu-wrap ul {
  width: 100%;
  font-size: 16px;
  list-style: none;
  display: flex;
  justify-content: space-between;
}
#main-menu .main-menu-wrap ul li {
  display: block;
  width: 150px;
  padding: 10px 30px;
  -webkit-transition: color 0.5s, transform 0.5s;
  transition: color 0.5s, transform 0.5s;
}
#main-menu .main-menu-wrap ul li:hover {
  color: red;
}
</style>
