/** When your routing table is too long, you can split it into small modules**/

const customer = {
  path: '/customer',
  component: () => import('@/layouts/MainLayout'),
  children: [
    {
      path: 'online',
      name: 'customerOnline',
      component: () => import('@/views/customer/Online')
    },
    {
      path: 'dealer',
      name: 'customerDealer',
      component: () => import('@/views/customer/Dealer')
    }
  ]
}

export default customer
